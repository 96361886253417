<template>
<div>

    <v-row>
        <v-col>
            <v-data-table :items-per-page='5'  :headers="headers" :items="getFamiliars" sort-by="calories" class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat >
                        <v-toolbar-title>Familiares</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-btn  color="primary" dark @click=" showDialog()">
                            <v-icon small> {{icons.mdiPlus}}</v-icon> Adicionar
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:item.birthday="{ item }">
                  {{formatDate(item.birthday)}}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon color="primary" small class="mr-2" @click="editItem(item)">
                        {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <v-icon color="error" small @click="deleteItem(item)">
                        {{ icons.mdiDeleteOutline }}
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    Nenhum registro encontrado.
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="600" persistent>
        <v-card>
            <BarTitleDialog :title="formTitle" @close="close()" />
            <v-card-text>
                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-text-field v-model="editedItem.name"  outlined hide-details label="Nome"></v-text-field>
                    </v-col>

                </v-row>
                <v-row>
                    <v-col>
                        <v-select v-model="editedItem.familiar_group" :items="familiar_group_items"  outlined hide-details label="Grupo Familiar"></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="editedItem.birthday" type="date" outlined hide-details label="Aniversário"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text x-large class="mr-4" @click="close()">Fechar</v-btn>
                <v-btn x-large color="primary" @click="save()" class="">Salvar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import {
    mdiClose,
    mdiDeleteOutline,
    mdiPencilOutline,
    mdiPlus
} from '@mdi/js'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import {
  mapGetters,
    mapMutations
} from 'vuex'

export default {
    components: {
        BarTitleDialog,
    },
    data: () => ({
        dialog: false,
        headers: [{
                text: 'Nome',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Grupo fam.',
                value: 'familiar_group'
            },
            {
                text: 'Aniversário',
                value: 'birthday'
            },
            {
                text: 'Ações',
                value: 'actions',
                align: 'end',
                sortable: false
            },
        ],
        familiar_group_items:[
          'Pai',
          'Mãe',
          'Tios',
          'Irmãos',
          'Outros'
        ],
        familiars: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          familiar_group: null,
          birthday:'' ,
        },
        defaultItem: {
           name: '',
          familiar_group: null,
          birthday:'' ,
        },
        icons: {
            mdiPencilOutline,
            mdiDeleteOutline,
            mdiPlus,
            mdiClose,
        },
    }),

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize()
    },
    computed: {
        ...mapGetters('guest',['getFamiliars']),
        formTitle() {
            return this.editedIndex === -1 ? 'Novo Familiar' : 'Editar Familiar'
        },
    },

    methods: {
        ...mapMutations('guest', ['setFamiliars']),
        initialize() {
            this.familiars = this.getFamiliars
        },
        showDialog() {
            this.dialog = true
        },
        editItem(item) {
            this.editedIndex = this.familiars.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },


        deleteItem(item) {
            this.editedIndex = this.familiars.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.familiars.splice(this.editedIndex, 1)
            this.setFamiliars(this.familiars)//atualiza na loja vuex
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })

        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            this.familiars = this.getFamiliars
            if (this.editedIndex > -1) {
                this.familiars = this.getFamiliars
                Object.assign(this.familiars[this.editedIndex], this.editedItem)
            } else {
                this.familiars.push(this.editedItem)
            }
            this.setFamiliars(this.familiars)
            this.close()

        },
         formatDate(date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${day}/${month}/${year}`
        },
    },
}
</script>
